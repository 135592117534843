import React from 'react';
import PropTypes from 'prop-types';

import { IsActiveBadge, IsInsuredBadge } from '@components/elements/Badge';

const LoanBadges = ({ loanId, isActive }) => {
  if (isActive) return [<IsActiveBadge isActive={isActive} />, <IsInsuredBadge loanId={loanId} />];

  return [<IsActiveBadge isActive={isActive} />];
};

LoanBadges.propTypes = {
  loanId: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default LoanBadges;
