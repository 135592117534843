import React from 'react';
import PropTypes from 'prop-types';

import AccountCard from '@components/containers/AccountCard';

import LoanBadges from '@components/modules/LoanBadges';

import accountBalanceIcon from '@assets/img/icons/account-balance.svg';

import getUrl from '@utils/getUrl';
import urls from '@constants/urls';
import useGetLoan from '@utils/useGetLoan';

const LoanCard = ({ loanId }) => {
  const { loan, loading } = useGetLoan(loanId);

  return (
    <AccountCard
      title="Lån"
      loading={loading}
      mainAmountTitle="Nuvarande skuld"
      mainAmountValue={loan.todaySolvingAmount}
      mainAmountIcon={accountBalanceIcon}
      monthlyPaymentTitle="Månadsbetalning"
      monthlyPaymentValue={loan.monthlyPayment}
      badges={<LoanBadges loanId={loanId} isActive={loan?.status === 'active'} />}
      viewMoreUrl={getUrl(urls.HOME.MY_LOANS.DETAILS, { loanId })}
      passedInvoicesPayed={loan.passedInvoicesPayed}
    />
  );
};

LoanCard.propTypes = {
  loanId: PropTypes.string.isRequired,
};

export default LoanCard;
